import { Card, CardMedia, CardContent, Typography } from "@mui/material";
import React from "react";



interface GameCardProps {
    redirectUrl: string,
    img: string,
    gameDisplayName: string,
    haveMargins?: boolean
}
interface GameCardState {
    hovered: boolean
}


export class GameCard extends React.Component<GameCardProps, GameCardState>
{
    constructor(props: GameCardProps) {
        super(props);
        this.state = {
            hovered: false
        };
    }

    render() {
        return (
            <>
                <Card
                    style={{
                        width: 400,
                        margin: "10px",
                        backgroundColor: this.state.hovered ? "#EEEEEE" : ""
                    }}
                    onMouseEnter={() => { this.setState({ hovered: true }) }}
                    onMouseLeave={() => { this.setState({ hovered: false }) }}
                    onClick={() => { window.location.href = this.props.redirectUrl }}
                >
                    <CardContent sx={{
                        textAlign: "left",
                        borderTop: "5px",
                    }}>
                        <Typography gutterBottom variant="h4" component="div" sx={{ textAlign: "center", fontFamily: "Lato" }}>
                            {this.props.gameDisplayName}
                        </Typography>
                    </CardContent>
                    <div
                        style={{
                            display: "flex",
                            alignContent: "flex-end",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center"
                        }}
                    >
                        <CardMedia
                            component="img"
                            image={this.props.img}
                            style={{
                                width: this.props.haveMargins ? "360px" : "400px",
                                height: this.props.haveMargins ? "360px" : "400px",
                                marginBottom: this.props.haveMargins ? "20px" : "0px",
                            }}

                        />
                    </div>
                </Card>
            </>
        );
    }
}
