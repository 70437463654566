import { AppBar, Box, Button, Container, Toolbar, Typography } from "@mui/material";
import React from "react";

import LOGO from "./Assets/bgglogo2.png";
import { BlueBrandColor, DefaultContainerStyle, GreyBrandColor } from "./BrandConstants";
import { PrimaryButton } from "./PrimaryButton";

interface HeaderProps {}
interface HeaderState {
    isLoggedIn: boolean; // Make an "active User" package for other dynamic UI
}

export class Header extends React.Component<HeaderProps, HeaderState> {
    constructor(props: HeaderProps) {
        super(props);
        this.state = {
            isLoggedIn: false,
        };
    }

    render() {
        const headerButtonStyle = { my: 2, color: GreyBrandColor, display: "block" };
        return (
            <>
                <AppBar position="static" sx={DefaultContainerStyle}>
                    <Container maxWidth="xl" sx={DefaultContainerStyle}>
                        <Toolbar disableGutters sx={{ ...DefaultContainerStyle, height: "48px" }}>
                            <Box
                                sx={{
                                    flexGrow: 1,
                                    display: "flex",
                                    flexAlign: "center",
                                    alignItems: "center",
                                }}
                            >
                                <img src={LOGO} height={"48px"} style={{ paddingRight: "8px" }} />
                                <Typography
                                    variant="h5"
                                    noWrap
                                    component="div"
                                    sx={{ display: { xs: "none", sm: "block", color: GreyBrandColor } }}
                                >
                                    Blue <span style={{ color: BlueBrandColor }}>Grey</span> Games
                                </Typography>
                            </Box>
                            <Box sx={{ display: "flex" }}>
                                <Button onClick={() => {}} sx={headerButtonStyle}>
                                    Products
                                </Button>
                                <Button onClick={() => {}} sx={headerButtonStyle}>
                                    About
                                </Button>
                                {!this.state.isLoggedIn && (
                                    <>
                                        <Button
                                            onClick={() => {
                                                this.setState({ isLoggedIn: true });
                                            }}
                                            sx={headerButtonStyle}
                                        >
                                            Login
                                        </Button>
                                        <PrimaryButton
                                            onClick={() => {
                                                this.setState({ isLoggedIn: true });
                                            }}
                                            sx={{ my: 2, display: "block" }}
                                        >
                                            Signup
                                        </PrimaryButton>
                                    </>
                                )}
                                {this.state.isLoggedIn && (
                                    <Button
                                        onClick={() => {
                                            this.setState({ isLoggedIn: true });
                                        }}
                                        sx={headerButtonStyle}
                                    >
                                        My Games
                                    </Button>
                                )}
                            </Box>
                        </Toolbar>
                    </Container>
                </AppBar>
            </>
        );
    }
}
