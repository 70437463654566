import { Toolbar, Typography } from "@mui/material";
import React from "react";

import { GreyBrandColor } from "./BrandConstants";

export class Footer extends React.Component {
    render() {
        return (
            <>
                <Toolbar
                    disableGutters
                    sx={{
                        height: "30px",
                        justifyContent: "flex-end",
                    }}
                >
                    <Typography
                        component="div"
                        sx={{
                            display: {
                                color: GreyBrandColor,
                                paddingRight: "16px",
                            },
                        }}
                    >
                        {"(c) 2022 Blue Grey Games"}
                    </Typography>
                </Toolbar>
            </>
        );
    }
}
