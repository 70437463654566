import { Box, Button, Card, Container, Typography } from "@mui/material";
import React from "react";

import EVERYTHING_LOGO from "./Assets/grouplogo1.png";
import GAUNTLET_LOGO from "./Assets/gauntlet.png";
import CAPTAIN_LOGO from "./Assets/pirate.png";
import { GameCard } from "./GameCard";
import { Header } from "./Header";
import { BlueBrandColor, DefaultContainerStyle, GreyBrandColor } from "./BrandConstants";
import { PrimaryButton } from "./PrimaryButton";
import { Footer } from "./Footer";

interface HomeProps {}
interface HomeState {}

export class Home extends React.Component<HomeProps, HomeState> {
    constructor(props: HomeProps) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <>
                <Header />
                <Container>
                    <Box
                        sx={{
                            maxWidth: "640px",
                            margin: "auto",
                            paddingTop: "48px",
                            paddingBottom: "16px",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}
                    >
                        <Box>
                            <Typography
                                variant="h3"
                                component="div"
                                sx={{ ...DefaultContainerStyle, color: GreyBrandColor }}
                            >
                                Really impactful <span style={{ color: BlueBrandColor }}>SEO words</span> that set a
                                mood
                            </Typography>
                        </Box>
                        <Box sx={{ padding: "32px" }}>
                            <Typography variant="h6" component="div" sx={DefaultContainerStyle}>
                                <b>Blue Grey Games</b> blends the boundaries between digital and physical experiences,
                                through great games (or some other bullshit)
                            </Typography>
                        </Box>
                        <PrimaryButton sx={{ margin: "auto" }}>Call to action</PrimaryButton>
                    </Box>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            flexWrap: "wrap",
                        }}
                    >
                        <GameCard
                            img={EVERYTHING_LOGO}
                            gameDisplayName="everything is game"
                            redirectUrl="https://everythingisgame.com"
                            haveMargins={true}
                        />
                    </div>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            flexWrap: "wrap",
                        }}
                    >
                        <GameCard
                            img={CAPTAIN_LOGO}
                            gameDisplayName="Captain"
                            redirectUrl="https://tylersbirthday.com"
                        />
                        <GameCard
                            img={GAUNTLET_LOGO}
                            gameDisplayName="Gauntlet"
                            redirectUrl="http://birthdaygauntlet.com/"
                        />
                    </div>
                </Container>
                <Footer />
            </>
        );
    }
}
