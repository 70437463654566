import { Button, ButtonProps } from "@mui/material";
import React from "react";

import { BlueBrandColor, WhiteBrandColor } from "./BrandConstants";

interface PrimaryButtonProps extends ButtonProps {}

export class PrimaryButton extends React.Component<PrimaryButtonProps> {
    render() {
        return (
            <>
                <Button
                    variant="contained"
                    {...this.props}
                    sx={{ background: BlueBrandColor, color: WhiteBrandColor, ...this.props.sx }}
                ></Button>
            </>
        );
    }
}
